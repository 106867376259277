import { React, useState, useEffect } from "react";
import tw, { css } from "twin.macro";

// ========= COMPONENTS =========
import PublicCard from "./PublicPledge";

// ========= UTILS =========
import { getPlayerState, updatePlayerState } from "../../libs/auth";
import useLogin from "../../hooks/useLogin";
import { Link } from "gatsby";

// ========= Assets =========
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShare } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as Scribble } from "../../assets/img/Scribble Hand.svg";
import copy from "../../assets/copy";
import ShareButton from "./ShareButton";

const MyPledge = () => {
	// Get authenticated user's pledge
	const user = useLogin();
	const [pledge, setPledge] = useState();
	useEffect(() => {
		if (!user) return;

		async function fetchPlayerState() {
			const ps = await getPlayerState();
			setPledge(ps?.pledge);
		}

		fetchPlayerState();
	}, [user]);

	useEffect(() => {
		if (!pledge?.action && !pledge?.lesson && !pledge?.subject)
			setPledge();
		if (!pledge?.signature) setPledge();
	}, [pledge]);

	const [share, setShare] = useState(false);

	// ========= RENDER =========
	// if there's no pledge
	if (!pledge)
		return (
			<div tw="border border-projectBlue-600 bg-projectBlue-900 rounded-button py-4 px-6 md:py-10 md:px-16 w-full h-full">
				<Link
					to="/"
					onClick={() => {
						updatePlayerState({
							skippedPledge: false,
						});
					}}
				>
					<div
						css={[
							tw`
								w-full h-full pointer-events-none
						flex flex-col justify-center items-center
								text-center text-white font-bold
							`,
						]}
					>
						<Scribble css={tw`mb-4`} />
						<span tw="text-base mb-3">
							{copy.pledgeWall.join}
						</span>
						<div tw="bg-red rounded-full text-sm px-8 pb-2 pt-2">
							{copy.pledgeWall.sign}
						</div>
					</div>
				</Link>
			</div>
		);

	// if there is a pledge
	return (
		<div
			css={tw`border border-projectBlue-400 rounded-button py-4 px-6 md:py-10 md:px-16 w-full h-full`}
		>
			<div tw="w-full h-full flex justify-center items-center">
				<div tw="flex flex-col items-start">
					<h3
						css={tw`text-yellow mb-2 font-bold text-xl`}
					>
						{copy.pledgeWall.yours}
					</h3>
					<div tw="w-full">
						{share ? (
							<ShareButton
								contentURL={`${process.env.SITE_URL}/pledge-wall/?u=${user.uid}`}
								icons
								onClose={() => setShare(false)}
								closeColor={tw`text-projectBlue-900`}
							/>
						) : (
							<PublicCard
								pledge={pledge}
								text={tw`text-xl text-red`}
								bg={tw`bg-gray-100`}
							/>
						)}
					</div>

					<div
						css={tw`inline-block mt-2 text-sm font-bold cursor-pointer`}
						onClick={() => setShare(true)}
					>
						{copy.pledgeWall.share}
						<FontAwesomeIcon
							css={[
								tw`
									inline-block ml-2
								`,
							]}
							icon={faShare}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default MyPledge;
