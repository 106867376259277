import { React, useEffect, useState } from "react";
import tw from "twin.macro";
import { graphql } from "gatsby";

// ========= COMPONENTS =========
import SEO from "../components/SEO";
import PageBackground from "../components/Elements/PageBackground";
import NavBar from "../components/Elements/NavBar";
import PublicCard from "../components/Elements/PublicPledge";
import CelebCard from "../components/Elements/CelebPledge";
import MyPledge from "../components/Elements/MyPledge";
import "../assets/grid-masonry.css";

// ========= UTILS =========
import { ENUMS } from "../libs/globals";
import { getAllPledges } from "../libs/auth";

// ========= Assets =========

import logoURL from "../assets/img/brand/PenLogo.png";
import copy from "../assets/copy";

import useLogin from "../hooks/useLogin";
import { useQueryParam, StringParam } from "use-query-params";

// ========= CUSTOM =========
const Wrapper = tw.div`
	xl:pl-64 lg:pl-48 lg:mt-12 mt-10 lg:pr-0
`;

const shuffleArray = (_array) => {
	const array = [..._array];
	for (let i = array.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * i);
		const temp = array[i];
		array[i] = array[j];
		array[j] = temp;
	}
	return array;
};

// ========= MAIN =========
const PledgeWall = ({ data }) => {
	// const user = useLogin();

	// look for a q query
	const [refferalID, setReferralID] = useQueryParam(
		`u`,
		StringParam
	);

	// const [robot] = useQueryParam(`robot`, BooleanParam);

	// useEffect(() => {
	// 	if (!user) return;
	// 	if (robot) return;
	// 	// setReferralID(user.uid);
	// }, [user, robot]);

	const [pledges, setPledges] = useState();
	const [pledgeDeck, setPledgeDeck] = useState([]);

	// get all user pledges from firebase
	useEffect(() => {
		const getAllPledgesFromFirebase = async () => {
			const p = await getAllPledges();
			setPledges(p);
		};
		getAllPledgesFromFirebase();
	}, []);

	// create the pledge deck
	useEffect(() => {
		if (!pledges) return;

		// create all the public pledge components
		const pledgeSet = pledges.map((pledge, i) => (
			<PublicCard
				key={`pledge-${i}`}
				pledge={pledge.pledge}
				uid={pledge.uid}
			/>
		));

		// create all the celebrity pledge components
		const celebSet = data.allSanityCelebrity.nodes.map(
			(celebrity, i) => (
				<CelebCard key={`celeb-${i}`} pledge={celebrity} />
			)
		);

		// merge and shuffle them
		const combinedSet = shuffleArray([
			...pledgeSet,
			...celebSet,
		]);

		// set the state
		setPledgeDeck(combinedSet);
	}, [pledges]);

	// ========= RENDER =========
	if (!pledges) return <PageBackground />;
	return (
		<PageBackground>
			<SEO
				title="Pledge Wall"
				metaType={ENUMS.META_TYPES.PLEDGE}
				social={{ uid: refferalID }}
			/>
			<Wrapper>
				<div
					css={[
						tw`block lg:hidden w-full flex items-center justify-between mb-6 px-2`,
					]}
				>
					<div tw="w-1/2 py-2">
						<h1
							css={[
								tw`
									font-bold uppercase
									text-white
									text-lg
									leading-none
								`,
							]}
						>
							{copy.labels.expect}
						</h1>
						<h2
							css={[
								tw`
									italic
									text-lg text-white
								`,
							]}
						>
							{copy.labels.when.ELECTING.text}
						</h2>
					</div>
					<img
						css={tw`w-20`}
						src={logoURL}
						alt="PEN America IMG"
					/>
				</div>

				{/* <div css={[tw`md:flex`]}>
					<a
						href="#"
						css={[
							tw`
								block md:inline-block bg-black
								text-sm md:text-base text-center text-white font-bold
								sm:px-6 py-2 mb-4 md:ml-auto
								rounded-full
							`,
						]}
					>
						{copy.pledgeWall.action}
						<FontAwesomeIcon
							css={[
								tw`
									inline-block ml-2
								`,
							]}
							icon={faShare}
						/>
					</a>
				</div> */}
				<div className="grid-layout">
					<div
						className="grid-item grid-span-col-2 grid-span-row-2"
						css={tw`bg-projectBlue-1000 text-white rounded-lg p-5`}
					>
						<MyPledge />
					</div>
					{pledgeDeck}
				</div>
			</Wrapper>
			<NavBar activePage={ENUMS.NAV.PLEDGE_WALL} />
		</PageBackground>
	);
};

export default PledgeWall;

export const query = graphql`
	query Celebrities {
		allSanityCelebrity {
			nodes {
				name
				color
				lesson
				subject
				action
				portrait {
					asset {
						fixed {
							src
						}
					}
				}
				signature {
					asset {
						fixed {
							src
						}
					}
				}
			}
		}
	}
`;
