import { React, useState } from "react";
import tw, { css } from "twin.macro";
import copy from "../../assets/copy";

import { motion, AnimatePresence } from "framer-motion";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faTimesCircle as closeIcon,
	faExclamationCircle as flagIcon,
} from "@fortawesome/free-solid-svg-icons";
import { flagSignature } from "../../libs/auth";

const PublicCard = ({
	pledge,
	uid,
	text = tw`text-projectBlue-800`,
	bg = tw`bg-white`,
}) => {
	const [isFullScreen, setIsFullScreen] = useState(false);
	const [isFlagged, setIsFlagged] = useState(false);

	const pledgeEnter = {
		show: {
			scale: 1,
			opacity: 1,
			duration: 0.1,
		},
		initial: {
			opacity: 0,
			duration: 0.1,
		},
		exit: {
			scale: 1.2,
			opacity: 0,
			duration: 0.1,
		},
	};
	const pledgeExit = {
		show: {
			opacity: 1,
			duration: 0.1,
		},
		initial: {
			opacity: 0,
			duration: 0.1,
		},
		exit: {
			opacity: 0,
			duration: 0.1,
		},
	};

	return (
		<div
			css={[
				tw` rounded-lg
				`,
			]}
		>
			<AnimatePresence initial={false}>
				{isFullScreen ? (
					<motion.div
						key="full-screen-pledge-card"
						css={[
							tw`
								fixed top-0 left-0 z-50 w-full h-screen
								flex flex-wrap justify-center items-center
								rounded-lg shadow-lg
								px-4 pb-10 pt-8
							`,
							css`
								z-index: 99;
							`,
							text,
							bg,
						]}
						variants={pledgeExit}
						initial="initial"
						animate="show"
						exit="exit"
					>
						<motion.div
							css={tw`max-w-sm mx-auto relative`}
							animate={{
								opacity: 1,
							}}
						>
							<div
								css={[
									tw`absolute right-0 top-0 cursor-pointer`,
									css`
										margin-top: -100px;
									`,
								]}
							>
								<FontAwesomeIcon
									icon={closeIcon}
									onClick={() => {
										setIsFullScreen(false);
									}}
								/>
							</div>

							<h1
								css={[
									tw`
										font-bold
										leading-tight
										mb-2
										text-2xl
									`,
								]}
							>
								{!pledge?.action &&
								!pledge?.subject &&
								!pledge?.lesson
									? `${copy.pledge.ready} ${copy.pledge.empty}.`
									: `${`${copy.pledge.ready} ${pledge?.action} ${pledge?.subject} ${pledge?.lesson}`.trimEnd()}.`}
							</h1>
							<img
								alt="Signature"
								src={pledge?.signature}
								css={[
									css`
										filter: invert(1);
									`,
								]}
							/>
							<div
								css={tw`text-xs mt-6 text-red flex items-center cursor-pointer`}
								onClick={() => {
									setIsFlagged(true);
									flagSignature({
										uid,
										image: pledge?.signature,
									});
								}}
							>
								<span>
									{isFlagged
										? copy.pledgeWall.flagged
										: copy.pledgeWall.flag}
								</span>
								{!isFlagged && (
									<FontAwesomeIcon
										icon={flagIcon}
										tw="ml-1"
									/>
								)}
							</div>
						</motion.div>
					</motion.div>
				) : (
					<motion.div
						key="pledge-card"
						onClick={() => {
							setIsFullScreen(true);
						}}
						css={[
							tw`
								rounded-lg shadow-lg
								cursor-pointer
								px-4 pb-10 pt-8 h-full
							`,
							text,
							bg,
						]}
						whileTap={{ scale: 0.98 }}
						variants={pledgeEnter}
						initial="initial"
						animate="show"
						exit="exit"
					>
						<div>
							<h1
								css={[
									tw`
										font-bold
										leading-tight
										mb-4
										text-lg
									`,
								]}
							>
								{!pledge?.action &&
								!pledge?.subject &&
								!pledge?.lesson
									? `${copy.pledge.ready} ${copy.pledge.empty}.`
									: `${`${copy.pledge.ready} ${pledge?.action} ${pledge?.subject} ${pledge?.lesson}`.trimEnd()}.`}
							</h1>
							<img
								alt="Signature"
								src={pledge?.signature}
								css={[
									css`
										filter: invert(1);
									`,
								]}
							/>
						</div>
					</motion.div>
				)}
			</AnimatePresence>
		</div>
	);
};
export default PublicCard;
