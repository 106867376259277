import { React, useState } from "react";
import tw, { css } from "twin.macro";
import copy from "../../assets/copy";
import { motion, AnimatePresence } from "framer-motion";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle as closeIcon } from "@fortawesome/free-solid-svg-icons";

const CelebCard = ({ pledge }) => {
	const {
		color,
		name,
		lesson,
		subject,
		action,
		signature,
		portrait,
	} = pledge;

	const [isFullScreen, setIsFullScreen] = useState(false);

	const pledgeEnter = {
		show: {
			scale: 1,
			opacity: 1,
			duration: 0.1,
		},
		initial: {
			opacity: 0,
			duration: 0.1,
		},
		exit: {
			opacity: 0,
			duration: 0.1,
		},
	};
	const pledgeExit = {
		show: {
			opacity: 1,
			duration: 0.1,
		},
		initial: {
			opacity: 0,
			duration: 0.1,
		},
		exit: {
			opacity: 0,
			duration: 0.1,
		},
	};

	return (
		<div
			className="grid-item grid-span-row-2"
			css={[
				tw` rounded-lg
				`,
			]}
		>
			<AnimatePresence initial={false}>
				{isFullScreen ? (
					<motion.div
						key="full-screen-pledge-card"
						css={[
							tw`
								fixed top-0 left-0 z-50 w-full h-screen
								flex flex-wrap justify-center items-center
								rounded-lg shadow-lg
								px-4 pb-10 pt-8
							`,
							css`
								background: ${color};
								z-index: 99;
							`,
						]}
						variants={pledgeExit}
						initial="initial"
						animate="show"
						exit="exit"
					>
						<motion.div
							css={tw`max-w-sm mx-auto relative`}
							animate={{
								opacity: 1,
							}}
							onClick={() => {
								setIsFullScreen(false);
							}}
						>
							<div
								css={[
									tw`absolute right-0 top-0 cursor-pointer`,
									css`
										margin-top: -100px;
									`,
								]}
							>
								<FontAwesomeIcon icon={closeIcon} />
							</div>
							<img
								css={[
									tw`rounded-full w-20 h-20 mb-4`,
								]}
								alt="Portrait"
								src={portrait.asset.fixed.src}
							/>
							<h2
								css={tw`uppercase text-xs md:text-base tracking-widest mb-3`}
							>
								{name}
							</h2>
							<h1
								css={[
									tw`
						font-bold
						leading-tight
						mb-2
						text-lg
						md:text-2xl
					`,
								]}
							>
								{!action && !subject && !lesson
									? `${copy.pledge.ready} ${copy.pledge.empty}.`
									: `${`${copy.pledge.ready} ${action} ${subject} ${lesson}`.trimEnd()}.`}
							</h1>
							<img
								alt="Signature"
								css={[
									css`
										filter: invert(1);
									`,
								]}
								src={signature.asset.fixed.src}
							/>
						</motion.div>
					</motion.div>
				) : (
					<motion.div
						key="pledge-card"
						onClick={() => {
							setIsFullScreen(true);
						}}
						css={[
							tw`
								rounded-lg shadow-lg
								cursor-pointer
								px-4 pb-10 pt-8 h-full
								flex flex-col justify-center items-start
								text-white
							`,
							css`
								background: ${color};
							`,
						]}
						whileTap={{ scale: 0.98 }}
						variants={pledgeEnter}
						initial="initial"
						animate="show"
						exit="exit"
					>
						<div>
							<img
								css={[
									tw`rounded-full w-20 h-20 mb-4`,
								]}
								alt="Portrait"
								src={portrait.asset.fixed.src}
							/>
							<h2
								css={tw`font-bold uppercase text-xs tracking-widest mb-3`}
							>
								{name}
							</h2>
							<h1
								css={[
									tw`
										font-bold
										leading-tight
										mb-2
										text-sm
										md:text-base
									`,
								]}
							>
								{!action && !subject && !lesson
									? `${copy.pledge.ready} ${copy.pledge.empty}.`
									: `${`${copy.pledge.ready} ${action} ${subject} ${lesson}`.trimEnd()}.`}
							</h1>
							<img
								alt="Signature"
								src={signature.asset.fixed.src}
								css={css`
									filter: invert(1);
								`}
							/>
						</div>
					</motion.div>
				)}
			</AnimatePresence>
		</div>
	);
};

export default CelebCard;
